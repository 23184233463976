<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <!-- <div
          class="menu-link"
          v-if="checkPermission('Suivi des paiement Obligé')"
        >
          <router-link class="menu-item" to="/obligee/suivi-paiement-obligee">
            Suivi des paiement Obligé
          </router-link>
        </div> -->
        <div class="menu-link" v-if="checkPermission('GAPTH')">
          <router-link
            class="menu-item"
            to="/obligee/gestion-appels-paiement-obligee"
          >
            Gestions des appels à paiement
          </router-link>
        </div>
        <!-- <div class="menu-link" v-if="checkPermission('GOTHS')">
          <router-link class="menu-item" to="/obligee/statistic-oblige">
            Statistique
          </router-link>
        </div> -->
        <div class="notif-export-file" v-if="getShowNotifExportFile">
          Préparation du fichier en cours
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'checkPermission', 'getShowNotifExportFile'])
  }
};
</script>

<style scoped lang="scss"></style>
