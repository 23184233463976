<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div class="menu-link d-flex">
          <div class="mr-1" v-if="checkPermission('GAPC')">
            <router-link class="menu-item" to="/commercial/appel-paiement">
              Appel a paiement
            </router-link>
          </div>
          <div class="ml-1" v-if="checkPermission('FCTH')">
            <router-link class="menu-item" to="/commercial/facture-commercial">
              Factures commerciaux
            </router-link>
          </div>
        </div>
        <!-- <div class="notif-export-file" v-if="getShowNotifExportFile">
          Préparation du fichier en cours
        </div> -->
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'checkPermission', 'getShowNotifExportFile'])
  }
};
</script>

<style scoped lang="scss"></style>
